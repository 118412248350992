<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../../helper/Gen';
	import Popper from 'vue-popperjs';
	import 'vue-popperjs/dist/vue-popper.css';

	export default {
		extends: BaseVue,
		data() {
			return {
				favorite:{},
				highlight:{},
				banner:{},
				input:{},
				Pagination:{}
			}
		},
		computed:{
			upic(){
				return this.user && Gen.userToken() ? this.user.pic : ''
			},
			uemail(){
				return this.user && Gen.userToken() ? this.user.email : ''
			},
			ufullname(){
				return this.user && Gen.userToken() ? this.user.fullname : ''
			},
			myParams(){
				let query = Gen.clone(this.$route.query)
				return query
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (this.$route.name) {
				setTimeout(() => {
					SEMICOLON.widget.carousel();
					$('[data-toggle="popover"]').popover()
				}, 300)
			}
			// setTimeout(() => { SEMICOLON.documentOnLoad.init() }, 100);
			if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
			setTimeout(() => {
				// SEMICOLON.slider.init()
				SEMICOLON.documentOnLoad.init()
				SEMICOLON.widget.carousel();
				SEMICOLON.documentOnResize.init()
				$('[data-toggle="popover"]').popover()
			}, 200)
			$(document).ready(() => {
				AOS.init()
			})
			this.getFavoriteProduct()
			this.getHighlightProduct()
			this.getBanner()
			this.getData()
		},
		methods: {
			getBanner(){
				Gen.apirest("/dashboard-banner", this.myParams,(err,resp)=>{
					if(err) console.log(err)
					this.banner = resp.data
				})
			},
			getHighlightProduct(){
				Gen.apirest("/dashboard-higlight-product", this.myParams,(err,resp)=>{
					if(err) console.log(err)
					this.highlight = resp.data
				})
			},
			getFavoriteProduct(){
				Gen.apirest("/dashboard-favorite-product", this.myParams,(err,resp)=>{
					if(err) console.log(err)
					this.favorite = resp.data.data
					this.Pagination = resp.data
				})
			},
			onPaging(page = 1){
				if($(".page-item.active").text()==page) return
				var query = Object.assign(Gen.clone(this.$route.query),{
					page:page
				})
				this.$router.push({
					name:this.$route.name,
					query:query
				})
			},
			getData(){
				Gen.apirest("/get-account-setting",{}, (err,resp)=>{
					if(err) return console.log(err)
					this.input = resp.data		
				})
			},
			onSubmit(){
				Gen.apirest("/submit-account-setting",this.input, (err,resp)=>{
					if(err) return Gen.info(err.resp.message, 'warning')
					this.getData()
					this.refreshVariableFo()
					Gen.info(resp.message, 'success')
				},"POST")
			},
			modalAccSetting(){
				VModal.openModal("ModalAccountSetting", {}, () => {})
			},
			addFavoriteDashboard(v){
		        Gen.apirest("/post-favorite",{data:v.apd_id,id:this.user.u_id},(err,resp)=>{
		            if(err) console.log(err)
		              if(resp.status === 'Y'){
		                  $('#fav_'+v.apd_id).find('i').removeClass('icon-line-heart');
		                  $('#fav_'+v.apd_id).find('i').addClass('icon-heart3');
		              } else {
		                  $('#fav_'+v.apd_id).find('i').removeClass('icon-heart3');
		                  $('#fav_'+v.apd_id).find('i').addClass('icon-line-heart');
		              }
					this.getFavoriteProduct()
		        })
		    }
		},
		watch:{
			$route(){
				this.getFavoriteProduct()
			},
		}
	};
</script>
<template>
	<section id="content" style="background-color: #000; overflow: visible">
		<div class="content-wrap pt-0 pb-0">
			<div id="section-about" class="section page-section bg_white nobottommargin notopmargin clearfix">
				<div class="container clearfix">
					<div class="row">
						<div class="col-lg-8 col-md-6">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><router-link :to="{name:'Dashboard'}">{{web.mn_dashboard}}</router-link></li>
								<li class="breadcrumb-item active" aria-current="page">{{web.mn_my_favourite}}</li>
							</ol>
						</div>
						<div class="col-lg-4 text-right col-6 d-none d-lg-block d-md-block" v-if="highlight.length">
							<aside class="prod_category">
								<h3>{{web.lbl_product_highlight}}</h3>
							</aside>
						</div>
					</div>
					<div class="row justify-content-around dark">
						<div class="col-lg-4 col-md-5">
							<div class="box_profile text-center">
								<div class="ava_wrap">
									<img v-if="input.pic"  class="rounded-circle foto_profile" :src="uploader(input.pic)" :alt="ufullname" :title="ufullname" />
									<img v-else :src="assets('fo_images','wine_ava.png')" :alt="ufullname" :title="ufullname" />
								</div>
								<h3>{{ufullname}}</h3>
								<p>{{uemail}}</p>
								<a href="javascript:;" class="btn_line" @click="modalAccSetting">{{web.dsh_edit_profile_btn}}</a>
							</div>

							<a v-if="banner.status == 'Y'&&banner.image != null" class="box_profile cus-banner mb-0" :style="'background-image: url(\''+uploader(banner.image)+'\')'"></a>
							<div class="box_profile text-center" v-if="banner.status == 'Y'&&banner.text != null">
								<div v-html="banner.text"></div>
								<p v-if="banner.link"> <a target="_blank" :href="banner.link" class="btn_line">{{web.btn_browse}}</a> </p>
							</div>

							<!-- <div class="box_profile bg_0f181b">
								<div class="dark text-center">
									<h4 class="text-white pb-3 mb-0">{{web.dsh_edit_your_profile}}.</h4>
									<p>{{web.dsh_edit_your_profile_desc}}.</p>
									<a href="javascript:;" class="btn_line" @click="modalAccSetting">{{web.dsh_edit_profile_btn}}</a>
								</div>
							</div> -->
						</div>
						<div class="col-lg-8 col-md-7">
							<div class="d-md-none" v-if="highlight.length">
								<aside class="prod_category">
									<h3>{{web.lbl_product_highlight}}</h3>
								</aside>
							</div>
							<div class="row tight" v-if="highlight.length">
								<div class="col-lg-4 col-6" v-for="(v,k) in highlight" :key="k">
									<div class="wrap_prod_list">
										<div class="wrap_prod_thumb">
											<router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}">
												<img :src="uploader(v.apd_image)" :mobile-img-src="uploader(v.apd_image)" :alt="v.apd_name" :title="v.apd_name" />
											</router-link>
											<a href="javascript:;" @click="addFavoriteDashboard(v)" :id="'fav_'+v.apd_id" class="btn_fav">
												<i v-if="v.isFavorite=='Y'" class="icon-heart3"></i>
												<i v-if="v.isFavorite=='N'" class="icon-line-heart"></i>
											</a>
										</div>
										<div class="prod_desc">
											<h3><a href="javascript:;">{{v.apd_name}}</a></h3>
											<h4>{{v.apd_year}}</h4>
											<p>Rp {{v.price}}</p>
											<div class="rating_prod dashboard_ratting">
												<i :class="v.apd_review_start>=1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
	                                            <i :class="v.apd_review_start>1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
	                                            <i :class="v.apd_review_start>2 ? (v.apd_review_start > 2 && v.apd_review_start < 3 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat')  : 'icon-line-star' "></i>
	                                            <i :class="v.apd_review_start>3 ? (v.apd_review_start > 3 && v.apd_review_start < 4 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
	                                            <i :class="v.apd_review_start>4 ? (v.apd_review_start > 4 && v.apd_review_start < 5 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="text-md-right">
								<aside class="prod_category">
									<h3>{{web.lbl_caps_my_favourite}}</h3>
								</aside>
							</div>
							<div class="row tight">
								<div class="col-lg-4 col-6" v-for="(v,k) in favorite" :key="k">
									<div class="wrap_prod_list">
										<div class="wrap_prod_thumb">
											<router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}">
												<img :src="uploader(v.apd_image)" :mobile-img-src="uploader(v.apd_image)" :alt="v.apd_name" :title="v.apd_name" />
											</router-link>
											<a href="javascript:;" @click="addFavoriteDashboard(v)" :id="'fav_'+v.apd_id" class="btn_fav">
												<i v-if="v.isFavorite=='Y'" class="icon-heart3"></i>
												<i v-if="v.isFavorite=='N'" class="icon-line-heart"></i>
											</a>
										</div>
										<div class="prod_desc">
											<h3><a href="javascript:;">{{v.apd_name}}</a></h3>
											<h4>{{v.apd_year}}</h4>
											<p>Rp {{v.price}}</p>
											<div class="rating_prod dashboard_ratting">
												<i :class="v.apd_review_start>=1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
	                                            <i :class="v.apd_review_start>1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
	                                            <i :class="v.apd_review_start>2 ? (v.apd_review_start > 2 && v.apd_review_start < 3 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat')  : 'icon-line-star' "></i>
	                                            <i :class="v.apd_review_start>3 ? (v.apd_review_start > 3 && v.apd_review_start < 4 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
	                                            <i :class="v.apd_review_start>4 ? (v.apd_review_start > 4 && v.apd_review_start < 5 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-12 col-12" v-if="!favorite.length">
									<h4 class="text-center text-dark">{{web.dsh_not_collection}}</h4>
									<p class="mb-0 text-center"><router-link :to="{name:'Product'}" class="btn_cookie">{{web.btn_browse}}</router-link></p>
								</div>
								<div class="col-md-12 col-12">
									<Pagination :data="Pagination" :limit="6" @pagination-change-page="onPaging"></Pagination>
								</div>
							</div>
						</div>
					</div>
					<div class="clear"></div>
				</div>
			</div>
		</div>
	</section>
</template>